/* eslint-disable array-callback-return */
/* eslint-disable import/no-cycle */
import {
  FilterModel,
  FilterType,
  ICollectionIdsFilterDTO,
  IFilterDTO,
  IFilterModel,
  IFilterSelectionValue,
  IQueryParamsFilter,
  NonCollectionFilterModel,
} from '../../types/galleryTypes';
import {ColorFilterModel} from '../../models/ColorFilterModel';
import {ListFilterModel} from '../../models/ListFilterModel';
import {PriceFilterModel} from '../../models/PriceFilterModel';
import {CollectionFiltersService} from './CollectionFiltersService';
import {FilterConfigsService} from './FilterConfigsService';
import {FiltersApi} from '../../api/FiltersApi';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/site-store/SiteStore';
import {IFilter} from '@wix/wixstores-graphql-schema/dist/es/src/graphql-schema';
import _ from 'lodash';
import {CollectionFilterModel} from '../../models/CollectionFilterModel';
import {MultiCollectionFilterModel} from '../../models/MultiCollectionFilterModel';
import {GetFiltersQuery} from '../../graphql/queries-schema';
import {IFilterValue} from '@wix/wixstores-graphql-schema/dist/es/src';
import {Experiments, FilterTypeFromFetch} from '../../constants';
import {getFilterTypeToActiveOptionStrategy, QueryParamActiveOptionStrategy} from '../GalleryQueryParamsService';
import {mergeNameAndTypeSharedOptionAndModifierFilters} from './customizationFiltersService';
import {IFiltersMetadataApi} from '../../api/IFiltersMetadataApi';
import {FiltersExternalApi} from '../../api/FiltersExternalApi';
import {ExternalDataSourceConfig} from '../../types/externalDataSourceConfig';
import {
  ExternalCollectionsFilterWrapper,
  ExternalFilterWrapperDTO,
  ExternalProductFilters,
  Filters,
} from '../../types/filters';
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor';
import {generatePriceFilterOptions} from './filterUtils';

export type FiltersServiceConfig = {
  siteStore: SiteStore;
  mainCollectionId: string;
  filterConfigsService: FilterConfigsService;
  allProductsCategoryId: string;
  formatCurrency: ControllerFlowAPI['formatCurrency'];
  currency: string;
  usingMultiCollectionFilters?: boolean;
  externalDataSourceFiltersApi?: ExternalDataSourceConfig['getFiltersMetadata'];
};

export class FiltersService {
  private readonly siteStore: SiteStore;
  private readonly filterConfigsService: FilterConfigsService;
  private readonly allProductsCategoryId: string;
  private readonly formatCurrency: ControllerFlowAPI['formatCurrency'];
  private readonly currency: string;
  private readonly usingMultiCollectionFilters?: boolean;
  private readonly externalDataSourceFiltersApi?: ExternalDataSourceConfig['getFiltersMetadata'];
  private mainCollectionId: string;
  private filterModels: FilterModel[] = [];
  private readonly collectionFiltersService: CollectionFiltersService;
  private readonly filtersApi: IFiltersMetadataApi;

  constructor(config: FiltersServiceConfig) {
    this.siteStore = config.siteStore;
    this.mainCollectionId = config.mainCollectionId;
    this.filterConfigsService = config.filterConfigsService;
    this.allProductsCategoryId = config.allProductsCategoryId;
    this.formatCurrency = config.formatCurrency;
    this.currency = config.currency;
    this.usingMultiCollectionFilters = config.usingMultiCollectionFilters;
    this.externalDataSourceFiltersApi = config.externalDataSourceFiltersApi;

    this.collectionFiltersService = new CollectionFiltersService({
      mainCollectionId: this.mainCollectionId,
      filterConfigsService: this.filterConfigsService,
      usingMultiCollectionFilters: this.usingMultiCollectionFilters,
      usingExternalFilters: !!this.externalDataSourceFiltersApi,
    });

    this.filtersApi = this.createFiltersApi();
  }

  public async fetchFilters(): Promise<FilterModel[]> {
    const filterTypeDTOs = this.filterConfigsService.getFilterTypeDTOs();
    const filtersResponse = await this.filtersApi.getFilters(filterTypeDTOs, this.mainCollectionId);
    const filters = this.removeCollectionFiltersWithNoOptions(filtersResponse);
    this.removeCustomCollectionFiltersWithNoOptions(filters);
    this.filterModels = this.sortFilterModels(this.createFilterModels(filters));
    return this.filterModels;
  }

  public updateActiveFilterOptionsByQueryParams(
    queryParamsFilters: IQueryParamsFilter[],
    useNewFiltersQueryParamDecoder: boolean
  ): void {
    for (const queryParamFilter of queryParamsFilters) {
      const delimiter = /,|\|/g;
      let queryParamsValues: string[] = [];
      if (useNewFiltersQueryParamDecoder) {
        try {
          queryParamsValues = queryParamFilter.value.split(delimiter).map(decodeURIComponent);
        } catch {
          queryParamsValues = queryParamFilter.value.split(delimiter);
        }
      } else {
        queryParamsValues = queryParamFilter.value.split(delimiter);
      }

      this.filterModels.forEach((filterModel) => {
        if (filterModel.filterId === queryParamFilter.filterId) {
          const activeFilterOption = this.getActiveFiltersOptionInFilterModelFromQueryPrams(
            queryParamsValues,
            filterModel
          );
          if (activeFilterOption) {
            this.updateActiveFilterOptionByQueryParams(filterModel, activeFilterOption);
          }
        }
      });
    }
  }

  public updateActiveFilterOption(filterModel: IFilterModel, selectionValue: IFilterSelectionValue): void {
    filterModel.updateActiveOptions(selectionValue);
  }

  public getFilterModels(): FilterModel[] {
    return this.filterModels;
  }

  public getFilterModel(filterId: number): IFilterModel {
    return this.filterModels.find((fm) => fm.filterId === filterId) as IFilterModel;
  }

  public getFiltersDTO = (): Filters => {
    const selectedFilterDTOs = this.getFilterDTOs();

    if (!selectedFilterDTOs.length) {
      return null;
    }

    if (this.externalDataSourceFiltersApi) {
      return {
        $and: selectedFilterDTOs as ExternalFilterWrapperDTO[],
      } as ExternalProductFilters;
    }

    return {
      and: selectedFilterDTOs.map((filter) => {
        return {
          term: filter,
        };
      }),
    } as any;
  };

  public shouldSpecificCollectionQuery = (mainCollectionId: string): boolean => {
    if (mainCollectionId !== this.allProductsCategoryId || this.getNonCollectionFilterDTOs().length) {
      return false;
    }
    const collectionDTOs = this.getCollectionFilterDTOs();
    if (collectionDTOs.length !== 1) {
      return false;
    }
    return this.collectionFiltersService.shouldSpecificCollectionQuery(collectionDTOs[0]);
  };

  public getCollectionIdsFilterDTO(): ICollectionIdsFilterDTO {
    const collectionIdsFilter: ICollectionIdsFilterDTO = {mainCategory: this.mainCollectionId};

    if (
      this.isFilterModelExists(FilterType.COLLECTION) &&
      this.getSelectedCollectionFilterId() !== this.mainCollectionId
    ) {
      collectionIdsFilter.subCategory = this.getSelectedCollectionFilterId();
    }

    if (this.hasSelectedCustomCollectionIds()) {
      const selectedCustomCollections = this.collectionFiltersService.getSelectedCustomCollectionFilters(
        this.getFilterModelByType(FilterType.CUSTOM_COLLECTION) as MultiCollectionFilterModel[]
      );
      collectionIdsFilter.customCategories = selectedCustomCollections.map(
        (selectedCollection) => selectedCollection.activeOptions
      );
    }

    return collectionIdsFilter;
  }

  public resetFilters(): void {
    this.filterModels.forEach((fm) => {
      fm.resetActiveOptions();
    });
  }

  public hasSelectedFilters(): boolean {
    return !!this.getSelectedFilterTypes().length;
  }

  public getSelectedFilterTypes(): string[] {
    const activeFilterTypes = this.filterModels.filter((fm) => fm.hasActiveOptions()).map((fm) => fm.filterType);
    if (activeFilterTypes.includes(FilterType.COLLECTION)) {
      const collectionFilterModel = this.filterModels.find((fm) => fm.filterType === FilterType.COLLECTION) as
        | CollectionFilterModel
        | MultiCollectionFilterModel;

      if (!collectionFilterModel.isAffectingQuery()) {
        activeFilterTypes.splice(activeFilterTypes.indexOf(FilterType.COLLECTION), 1);
      }
    }

    return activeFilterTypes;
  }

  public getSelectedFilterOptionsCount(): number {
    const filterTypeToActiveOptionStrategy = getFilterTypeToActiveOptionStrategy(this.usingMultiCollectionFilters);
    const activeFilters = this.filterModels.filter((fm) => fm.hasActiveOptions());
    let activeFilterOptionsCount = 0;
    activeFilters.forEach((activeFilter) => {
      const optionStrategy = filterTypeToActiveOptionStrategy[activeFilter.filterType];
      if (
        optionStrategy === QueryParamActiveOptionStrategy.Price ||
        (optionStrategy === QueryParamActiveOptionStrategy.SingleCollection &&
          (activeFilter as CollectionFilterModel).isAffectingQuery())
      ) {
        activeFilterOptionsCount++;
      } else if (optionStrategy === QueryParamActiveOptionStrategy.MultiValue) {
        activeFilterOptionsCount += (activeFilter.activeOptions as string[]).length;
      }
    });

    return activeFilterOptionsCount;
  }

  public deleteFilterModels(): void {
    this.filterModels = [];
  }

  public setMainCollectionId = (mainCollectionId: string) => {
    this.mainCollectionId = mainCollectionId;
    this.collectionFiltersService.setMainCollectionId(mainCollectionId);
  };

  private getActiveFiltersOptionInFilterModelFromQueryPrams(
    queryParamsValues: string[],
    filterModel: FilterModel
  ): IFilterValue | IFilterValue[] {
    if (queryParamsValues.length > 1) {
      return queryParamsValues.map((val) => {
        if (filterModel.filterType === FilterType.PRICE) {
          return filterModel.options.find((option) => option.key === val);
        } else {
          return filterModel.options.find((option) => option.value === val);
        }
      });
    } else if (queryParamsValues[0] === 'All') {
      return {key: this.mainCollectionId, value: queryParamsValues[0]};
    } else {
      return filterModel.options.find((option) => option.value === queryParamsValues[0]);
    }
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  private updateActiveFilterOptionByQueryParams(
    filterModel: IFilterModel,
    activeFilterOption: IFilterValue | IFilterValue[]
  ): void {
    const strategy = getFilterTypeToActiveOptionStrategy(this.usingMultiCollectionFilters);
    const shouldOverrideValues = this.siteStore.experiments.enabled(Experiments.PreventGalleryFullRefreshOnUrlChange);
    switch (strategy[filterModel.filterType]) {
      case QueryParamActiveOptionStrategy.MultiValue: {
        if (shouldOverrideValues) {
          const optionIds: string[] = _.isArray(activeFilterOption)
            ? activeFilterOption.filter((o) => o !== undefined).map((option) => option.key)
            : [activeFilterOption.key];
          filterModel.overrideActiveOptions(optionIds);
          break;
        }
        if (_.isArray(activeFilterOption)) {
          for (const filterOption of activeFilterOption) {
            if (filterOption) {
              this.updateActiveFilterOption(filterModel, filterOption.key);
            }
          }
        } else {
          this.updateActiveFilterOption(filterModel, activeFilterOption.key);
        }
        break;
      }
      case QueryParamActiveOptionStrategy.SingleCollection: {
        if (shouldOverrideValues) {
          filterModel.overrideActiveOptions((activeFilterOption as IFilterValue).key);
        } else {
          this.updateActiveFilterOption(filterModel, (activeFilterOption as IFilterValue).key);
        }
        break;
      }
      case QueryParamActiveOptionStrategy.Price: {
        /* istanbul ignore next */
        if (activeFilterOption[0] && activeFilterOption[1]) {
          if (shouldOverrideValues) {
            filterModel.overrideActiveOptions({
              min: activeFilterOption[0].key,
              max: activeFilterOption[1].key,
            });
          } else {
            this.updateActiveFilterOption(filterModel, {
              min: activeFilterOption[0].key,
              max: activeFilterOption[1].key,
            });
          }
        }
      }
    }
  }

  private getFilterDTOs(): IFilterDTO[] | ExternalFilterWrapperDTO[] {
    if (!this.externalDataSourceFiltersApi) {
      return [...this.getCollectionFilterDTOs(), ...this.getNonCollectionFilterDTOs()];
    } else {
      const externalFilterDTOs = [...this.getNonCollectionExternalFilterDTOs()];
      const collectionExternalFilterDTO = this.getCollectionExternalFilterDTO();

      if (collectionExternalFilterDTO) {
        externalFilterDTOs.push(collectionExternalFilterDTO);
      }

      return externalFilterDTOs;
    }
  }

  private getSelectedCollectionFilterId(): string {
    return (this.getFilterModelByType(FilterType.COLLECTION)[0] as CollectionFilterModel).activeOptions;
  }

  private hasSelectedCustomCollectionIds(): boolean {
    let result = false;

    const customCollectionFilterModels = this.getFilterModelByType(
      FilterType.CUSTOM_COLLECTION
    ) as MultiCollectionFilterModel[];

    customCollectionFilterModels.forEach((fm) => {
      if (fm.hasActiveOptions()) {
        result = true;
      }
    });

    return result;
  }

  private getFilterModelByType(filterType: FilterType): FilterModel[] {
    return this.filterModels.filter((fm) => fm.filterType === filterType);
  }

  private createFilterModels(filters: IFilter[]): FilterModel[] {
    const assignFilterId = (filterModel, index) => {
      filterModel.filterId = index;
      return filterModel;
    };

    const flatten = (acc: FilterModel[], curr) => acc.concat(curr as FilterModel[]);

    return mergeNameAndTypeSharedOptionAndModifierFilters(filters)
      .map((filter) => {
        switch (filter.filterType) {
          case FilterTypeFromFetch.CATEGORY: {
            return this.collectionFiltersService.createCollectionFilterModel(filter.values);
          }
          case FilterTypeFromFetch.FILTERED_CATEGORIES: {
            return this.collectionFiltersService.createCustomCollectionFilterModels(filter.values);
          }
          case FilterTypeFromFetch.COLOR: {
            return new ColorFilterModel(FilterType.COLOR_OPTION, filter.name, filter.values, filter.field);
          }
          case FilterTypeFromFetch.LIST: {
            return new ListFilterModel(FilterType.LIST_OPTION, filter.name, filter.values, filter.field);
          }
          case FilterTypeFromFetch.PRICE: {
            const title = this.filterConfigsService.getFilterConfigTitle(FilterType.PRICE);

            if (
              this.externalDataSourceFiltersApi &&
              this.siteStore.experiments.enabled(Experiments.PriceFilterClientTicksCalculation)
            ) {
              const minPrice = Math.floor(parseFloat(filter.values[0].key));
              const maxPrice = Math.ceil(parseFloat(filter.values[filter.values.length - 1].key));
              const filterValues = generatePriceFilterOptions(minPrice, maxPrice, this.formatCurrency, this.currency);
              return new PriceFilterModel(FilterType.PRICE, title, filterValues, filter.field);
            } else {
              return new PriceFilterModel(FilterType.PRICE, title, filter.values, filter.field);
            }
          }
        }
      })
      .reduce(flatten, [])
      .map(assignFilterId);
  }

  private sortFilterModels(filterModels: FilterModel[]) {
    this.filterModels = this.sortColorAndListFiltersAlphabetically(filterModels);

    const sortingOrder = [
      FilterType.COLLECTION,
      FilterType.PRICE,
      FilterType.COLOR_OPTION,
      FilterType.LIST_OPTION,
      FilterType.CUSTOM_COLLECTION,
    ];

    return _.sortBy(this.filterModels, (fm) => {
      return sortingOrder.indexOf(fm.filterType);
    });
  }

  private sortColorAndListFiltersAlphabetically(filterModels: FilterModel[]) {
    return _.sortBy(filterModels, (fm) => {
      if (fm.filterType === FilterType.COLOR_OPTION || fm.filterType === FilterType.LIST_OPTION) {
        return fm.title;
      }
    });
  }

  private isCollectionFilter(filter: FilterModel): boolean {
    return filter.filterType === FilterType.CUSTOM_COLLECTION || filter.filterType === FilterType.COLLECTION;
  }

  private getCollectionFilterDTOs(): IFilterDTO[] {
    return this.collectionFiltersService.toDTOs(
      this.getFilterModelByType(FilterType.COLLECTION) as (MultiCollectionFilterModel | CollectionFilterModel)[],
      this.getFilterModelByType(FilterType.CUSTOM_COLLECTION) as MultiCollectionFilterModel[]
    );
  }

  private getCollectionExternalFilterDTO(): ExternalCollectionsFilterWrapper {
    return this.collectionFiltersService.toExternalDTO(
      this.getFilterModelByType(FilterType.COLLECTION) as CollectionFilterModel[]
    );
  }

  private getNonCollectionFilterModels(): FilterModel[] {
    return this.filterModels.filter((fm) => !this.isCollectionFilter(fm));
  }

  private getNonCollectionFilterDTOs(): IFilterDTO[] {
    return this.getNonCollectionFilterModels()
      .filter((fm: NonCollectionFilterModel) => fm.hasActiveOptions())
      .map((fm: NonCollectionFilterModel) => fm.toDTO());
  }

  private getNonCollectionExternalFilterDTOs(): ExternalFilterWrapperDTO[] {
    return this.getNonCollectionFilterModels()
      .filter((fm: NonCollectionFilterModel) => fm.hasActiveOptions())
      .map((fm: NonCollectionFilterModel) => fm.toExternalDTO());
  }

  private removeCollectionFiltersWithNoOptions(filters: GetFiltersQuery['catalog']['filters']): IFilter[] {
    if (this.externalDataSourceFiltersApi) {
      _.remove(filters, (filter) => {
        return filter.filterType === FilterTypeFromFetch.CATEGORY && filter.values.length === 0;
      });
    } else {
      _.remove(filters, (filter) => {
        return (
          filter.filterType === FilterTypeFromFetch.CATEGORY &&
          this.collectionFiltersService.getEnabledCollectionFilterOptions(filter.values).length === 0
        );
      });
    }

    return filters;
  }

  private removeCustomCollectionFiltersWithNoOptions(filters: IFilter[]): void {
    const customCollectionFilter = filters.find((f) => f.filterType === FilterTypeFromFetch.FILTERED_CATEGORIES);
    if (customCollectionFilter) {
      this.filterConfigsService.removeCustomCollectionFiltersWithNoOptions(customCollectionFilter.values);
    }
  }

  private isFilterModelExists(filterType: FilterType): boolean {
    return !!this.filterModels.find((fm) => fm.filterType === filterType);
  }

  private createFiltersApi(): IFiltersMetadataApi {
    return this.externalDataSourceFiltersApi
      ? new FiltersExternalApi(this.externalDataSourceFiltersApi)
      : new FiltersApi(this.siteStore);
  }
}
